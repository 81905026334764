.popupCon {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .popup {
    position: relative;
    width: 376px;
    background: #fff;
    padding: 67px 24px;
    text-align: center;
    .backButton {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 10px;
      margin-right: 10px;
      width: 40px;
      height: 40px;
      border: 2px solid #777;
      border-radius: 50%;
      line-height: 32px;
      color: #777;
      font-weight: 600;
      font-size: 20px;
      aspect-ratio: 1/1;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    .text {
      margin-top: 30px;
    }
  }
}
