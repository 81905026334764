.fade-in {
  animation: fadeInAnimation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-slide-up {
  animation: fadeInAnimation 3s 1.5 forwards, slideUp 1s forwards;
  animation-iteration-count: 1;
}

.--3-sec {
  animation-duration: 3s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}
