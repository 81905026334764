@charset "utf-8";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--font_color);
  padding: 15px 60px;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 100;
  @media (max-width: 992px) {
    row-gap: 10px;
  }
  @media (max-width: 576px) {
    // padding: 16px 24px;
    padding: 0px 0px 0px 12px;
    position: fixed;
    width: 100%;
  }
  .headerLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 39px;
    font-feature-settings: "palt";
    img {
      border: 2px solid #fff;

      @media (max-width: 992px) {
        height: 50px;
        width: auto;
      }
      @media (max-width: 576px) {
        height: 40px;
        width: 100px;
        display: inline;
        margin-right: 8px;
      }
    }
    @media (max-width: 992px) {
      gap: 20px;
    }
    @media (max-width: 576px) {
      padding-top: 5px;
      padding-bottom: 5px;
      width: calc(100vw - 145px);
      display: block;
    }
    .headertitle {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      @media (max-width: 820px) {
        font-size: 18px;
        margin: auto;
      }
      @media (max-width: 576px) {
        font-size: 12px;
        margin: 0;
        display: inline;
      }
    }
  }
  .headerTelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
    gap: 20px;
    font-feature-settings: "palt";
    
    @media (max-width: 992px) {
      gap: 20px;
      margin-left:auto;
    }
    @media (max-width: 576px) {
      flex-flow: row;
      align-items: baseline;
      gap: 16px;
    }
    .headertitle {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      @media (max-width: 992px) {
        font-size: 22px;
        margin: auto;
      }
      @media (max-width: 576px) {
        font-size: 18px;
        margin: auto;
      }
    }
    .headertitlesub {
      color: #fff;
      text-align: center;
      font-size: 11px;
      @media (max-width: 992px) {
        font-size: 6px;
        margin: auto;
      }
      @media (max-width: 576px) {
        font-size: 8px;
        margin: auto;
      }
    }
  }
  .headerButtonContainer {
    display: flex;
    align-items: center;
    gap: 14px;
    button {
      height: 50px;
      img {
        width: 27px;
      }
      span {
        font-weight: 700;
        display: inline-block;
        margin-left: 5px;
        vertical-align: -2px;
      }
    }
    @media (max-width: 576px) {
      flex-flow: row;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 100;
      gap: 5px;
    }
    @media (max-width: 576px) {
      display: none;
    }
    .headertitle {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      @media (max-width: 992px) {
        font-size: 22px;
        margin: auto;
      }
      @media (max-width: 576px) {
        font-size: 18px;
        margin: auto;
      }
    }
    .headertitlesub {
      color: #fff;
      text-align: center;
      font-size: 11px;
      @media (max-width: 992px) {
        font-size: 6px;
        margin: auto;
      }
      @media (max-width: 576px) {
        font-size: 8px;
        margin: auto;
      }
    }
    .headerButtonWrap {
      display: flex;
      align-items: center;
      gap: 14px;
      @media (max-width: 576px) {
        flex-flow: row;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 100;
        gap: 5px;
      }
      
      .half {
        @media (max-width: 576px) {
          width: 50%;
        }
      }
    }
  }
  .pcoff{
    display: none;
  }

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .pcoff{
    display: flex;
  }
  .headertitlesp {
    display: inline-block; /* Display as a block element to create a box-like appearance */
    padding: 10px 5px 10px 8px;
    background-color: #f97304;
    width: 130px;
    img{
      text-align: center;
      float: left;
      width: 30px !important;
      height: 30px !important;
    }
    .headertitlesubsp{
      color: #fff;
      font-size: 10px;
      margin-top: 0;
      text-align: left;
      padding-left: 36px;
    }
    .fontwight{
      font-weight: 700;
    }
  }


  /* You can adjust other styles for mobile devices here */
}

}

.MobileEstimateResultButtonCon {
  @media (min-width: 576px) {
    display: none;
  }
  background: var(--font_color);
  padding: 10px 60px;
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  z-index: 100;
  button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      left: 14px;
      padding: 14px;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      margin-left: 8px;
      vertical-align: -2px;
    }
  }
}

.contents {
  max-width: 1470px;
  width: 92%;
  // margin: 90px auto 0;
  @media (max-width: 576px) {
    margin: 20px auto 0;
    width: 99%;
  }
  margin: 0 auto;
  .container {
    max-width: 1470px;
  }
  .searchConditionSection {
    .easingAndSort {
      display: flex;
      width: 100%;
      @media (max-width: 576px) {
        flex-flow: column;
        width: 100%;
      }
      .easing {
        width: 20%;
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      .spacer {
        width: 10%;
      }
      .sort {
        width: 40%;
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      .intentionRecommendInsurance {
        width: 40%;
        @media (max-width: 576px) {
          width: 100%;
        }
        .buttongroupWrap {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    .searchButtonContainer {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      width: 100%;
      @media (max-width: 576px) {
        width: 100%;
        margin-bottom: 40px;
      }
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
      }
      .searchButton {
        padding: 12px 115px;
        border-radius: 30px;
      }
    }
    .notes {
      margin: 47px 0;
      @media (max-width: 576px) {
        display: none;
      }
    }
    .others {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        gap: 15px;
      }
      @media (max-width: 576px) {
        gap: 40px;
        flex-flow: column;
      }
      .dropdown {
        display: inline;
        .toggle {
          width: 175px;
          text-align: left;
          @media (max-width: 576px) {
            width: 100%;
          }
          .menu {
            width: 175px;
            @media (max-width: 576px) {
              width: 100%;
            }
          }
        }
        .toggle-guarantee {
          width: 320px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
    }
  }
  .comparisonTableSection {
    .speechBoxContainer {
      width: 180px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 576px) {
        display: none;
      }
      .speechBox {
        position: relative;
        border: 1px solid #000;
        display: block;
        line-height: 1.5;
        padding: 15px 15px;
        background: var(--font_color);
        color: #fff;
        font-weight: bolder;
        margin-bottom: 20px;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform-origin: bottom;
          transform: translateX(-50%) rotate(180deg);
          border-width: 15px;
          border-style: solid;
          border-color: transparent transparent var(--font_color) transparent;
        }
      }
    }
    .insuranceTableTitle {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 0;
      width: calc(100% - 200px);
      border: 1px solid #000;
      background: #fff;
      text-align: center;
      @media (max-width: 576px) {
        display: none;
      }
    }
    .mobileRankSection {
      display: flex;
      margin: 50px 0;
      margin-left: 20px;
      width: 100%;
      overflow-x: scroll;
      @media (min-width: 576px) {
        display: none;
      }
      .columnCommon {
        padding: 0 10px;
        min-width: 80%;
        .radioBtnContainer {
          display: none;
        }
        .lowPriceRankCon {
          font-weight: bold;
        }
        .companyNameAndFeeCon {
          .companyName {
            padding-top: 5px;
            padding-bottom: 10px;
          }
          .fee {
            padding-top: 20px;
            padding-bottom: 10px;
            font-weight: bold;
          }
        }
        .entryCon {
          .netBtn {
            padding: 15px 0;
            width: 80%;
          }
        }
        .saveCon {
          .addButton {
            margin: 5px 0;
            padding: 0 5px;
          }
        }
      }
    }
    .insuranceTable {
      display: flex;
      column-gap: 22px;
      @media (max-width: 576px) {
        column-gap: 0px;
      }
      .headRow {
        width: 180px;
        flex-shrink: 0;
        @media (max-width: 600px) {
          width: 120px;
        }
        .headRowCommon {
          .checkCompareButtonContainer {
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            .checkCompareButton {
              border: 1px solid #000;
              border-radius: 20px;
              display: block;
              line-height: 1;
              padding: 8px 11px;
              background: #fff;
            }
            .checkCompareButtonActive {
              background-color: #00153e;
              color: #fff;
            }
          }
        }
        .headRowSpecific {
          .twoColumns {
            display: grid;
            grid-template-columns: 25% 75%;
            .leftColumn {
              border-right: 0.5px solid #000;
              grid-row: 1/4;
              grid-column: 1/2;
              writing-mode: vertical-rl;
              display: flex;
              align-items: center;
              justify-content: center;
              .guaranteeText {
                letter-spacing: 1em;
              }
            }
            .rightColumn {
              grid-row: 1/4;
              grid-column: 2/4;
            }
            .listHead {
              text-align: center;
            }
          }
        }
      }
      .contentRow {
        display: flex;
        flex-flow: row;
        overflow-x: scroll;
        .plan {
          flex-shrink: 0;
          width: 180px;
          @media (max-width: 576px){
          width: 121px;
          .companyName {
            font-size: 12px;
          }
        }
          .columnCommon {
            .radioBtnContainer {
              height: 55px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .rankCon {
              font-weight: bold;
            }
            .companyNameCon {
              img {
                object-fit: contain;
                max-width: 90%;
              }
            }
            .feeCon {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .notesSection {
    margin: 60px 0 80px;
    font-size: 12px;
    @media (max-width: 576px){
      padding: 0 10px;
      .aboutThisSite {
        padding-left: 1.2em;
      }
    }
    .aboutThisSite {
      padding-left: 1em;
      list-style-type: disc;
      @media (max-width: 576px) {
        list-style: disc;
      }
    }
    p {
      margin-bottom: 10px;
    }
  }
}

.contentsnew {
  .searchConditionSection {
    margin: 90px auto 0;
    @media (max-width: 576px) {
      padding: 0 10px;
    }
    .easingAndSort {
      display: flex;
      width: 100%;
      @media (max-width: 576px) {
        flex-flow: column;
        width: 100%;
      }
      .easing {
        width: 20%;
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      .spacer {
        width: 10%;
      }
      .sort {
        width: 40%;
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      .intentionRecommendInsurance {
        width: 40%;
        @media (max-width: 576px) {
          width: 100%;
        }
      }
    }
    .searchButtonContainer {
      // display: flex;
      justify-content: center;
      margin-left: 30px;
      // margin-top: 70px;
      // width: 100%;
      @media (max-width: 576px) {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        margin-left: 0;
      }
      .searchButton {
        padding: 12px 115px;
        border-radius: 30px;
      }
    }
    .notes {
      // margin: 47px 0;
      padding-left: 200px;
      @media (max-width: 576px) {
        //display: none;
        padding: 0 10px;
      }
    }
    .others {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        gap: 15px;
      }
      @media (max-width: 576px) {
        gap: 10px;
        flex-flow: column;
      }
      .dropdown {
        display: inline;
        .toggle {
          width: 175px;
          text-align: left;
          @media (max-width: 576px) {
            width: 100%;
          }
          .menu {
            width: 175px;
            @media (max-width: 576px) {
              width: 100%;
            }
          }
        }
        .toggle-guarantee {
          width: 320px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
    }
  }
  .comparisonTableSection {
    .speechBoxContainer {
      width: 180px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 576px) {
        display: none;
      }
      .speechBox {
        position: relative;
        border: 1px solid #000;
        display: block;
        line-height: 1.5;
        padding: 15px 15px;
        background: var(--font_color);
        color: #fff;
        font-weight: bolder;
        margin-bottom: 20px;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform-origin: bottom;
          transform: translateX(-50%) rotate(180deg);
          border-width: 15px;
          border-style: solid;
          border-color: transparent transparent var(--font_color) transparent;
        }
      }
    }
    .insuranceTableTitle {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 0;
      width: calc(100% - 200px);
      border: 1px solid #000;
      background: #fff;
      text-align: center;
      @media (max-width: 576px) {
        display: none;
      }
    }
    .mobileRankSection {
      display: flex;
      margin: 50px 0;
      margin-left: 20px;
      width: 100%;
      overflow-x: scroll;
      @media (min-width: 576px) {
        display: none;
      }
      .columnCommon {
        padding: 0 10px;
        min-width: 80%;
        .radioBtnContainer {
          display: none;
        }
        .lowPriceRankCon {
          font-weight: bold;
        }
        .companyNameAndFeeCon {
          .companyName {
            padding-top: 5px;
            padding-bottom: 10px;
          }
          .fee {
            padding-top: 20px;
            padding-bottom: 10px;
            font-weight: bold;
          }
        }
        .entryCon {
          .netBtn {
            padding: 15px 0;
            width: 80%;
          }
        }
        .saveCon {
          .addButton {
            margin: 5px 0;
            padding: 0 5px;
          }
        }
      }
    }
    .insuranceTable {
      display: flex;
      column-gap: 22px;
      @media (max-width: 576px) {
        column-gap: 0px;
      }
      .headRow {
        width: 180px;
        flex-shrink: 0;
        @media (max-width: 600px) {
          width: 120px;
        }
        .headRowCommon {
          .checkCompareButtonContainer {
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            .checkCompareButton {
              border: 1px solid #000;
              border-radius: 20px;
              display: block;
              line-height: 1;
              padding: 8px 11px;
              background: #fff;
            }
            .checkCompareButtonActive {
              background-color: #00153e;
              color: #fff;
            }
          }
        }
        .headRowSpecific {
          .twoColumns {
            .leftColumn {
              border-right: 0.5px solid #000;
              grid-row: 1/4;
              grid-column: 1/2;
              writing-mode: vertical-rl;
              display: flex;
              align-items: center;
              justify-content: center;
              .guaranteeText {
                letter-spacing: 1em;
              }
            }
            .rightColumn {
              grid-row: 1/4;
              grid-column: 2/4;
            }
          }
        }
      }
      .contentRow {
        display: flex;
        flex-flow: row;
        overflow-x: scroll;
        .plan {
          flex-shrink: 0;
          width: 180px;
          .columnCommon {
            .radioBtnContainer {
              height: 55px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .rankCon {
              font-weight: bold;
            }
            .companyNameCon {
              img {
                object-fit: contain;
              }
            }
            .feeCon {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .notesSection {
    margin: 60px 0 80px;
    .aboutThisSite {
      padding-left: 1em;
      list-style-type: disc;
      list-style: none;
      @media (max-width: 576px) {
        list-style: disc;
      }
    }
  }
}
@media (min-width: 577px) {
.contentsnew {
  width: 100%;
  height: 46px;
  &.searchConditionSectionWrap{
    height: auto;
  }
  .searchConditionWrap {
    max-width: 1470px;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
  .searchConditionSection {
    margin: -36px auto 0;
    .insuranceTypeWrap{
      background-color: #fff;
      .insuranceType {
        max-width: 1470px;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        button {
          border-radius: 0 !important;
        }
        .buttongroupWrap {
          display: flex;
          justify-content: center;
        }
      }
    }

    .easingAndSort {
      display: flex;
      width: calc(100% - 200px);
      margin-left: 200px;

      @media (max-width: 820px) {
        width: 100%;
        margin-left: 0;
        display: flex;
        .searchButtonContainer {
          width: calc(40% - 15px);
          margin-left: 30px;
          button.searchButton {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
          }
        }
      }

      .easing {
        width: 20%;

        @media (max-width: 576px) {
          width: 100%;
        }
      }

      .spacer {
        width: 10%;
      }

      .sort {
        width: 40%;

        @media (max-width: 576px) {
          width: 100%;
        }
      }

      .intentionRecommendInsurance {
        width: calc(60% - 15px);

        @media (max-width: 576px) {
          width: 100%;
        }
        .buttongroupWrap {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    .others { 
      margin-left: 200px;
      width: calc(100% - 200px);
    }
  }
}
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--font_color);
  color: #ffffff;
  padding: 70px 0px;
  @media (max-width: 576px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .footerContents {
    max-width: 1000px;
    width: 92%;
    padding: 0 auto 0;
    @media (max-width: 576px) {
      p:nth-child(4) {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }
    }

    a {
      color: var(--sub_color_1);
      margin-right: 20px;
      &:hover {
        color: var(--bs-link-color);
      }
    }
  }
}

.myPlanResultPopup {
  .filter {
    background-color: rgba(0,0,0,.5);
    height: calc(100% - 137px);
    right: 30px;
    position: fixed;
    top: 100px;
    width: 600px;
    z-index: 1;
    @media (max-width: 576px) {
      height: calc(100vh - 65px);
      right: 5%;
      top: 20px;
      width: 90vw;
    }
  }
}
// css components
.buttongroupWrap {
  display: grid;
  button {
    padding: 10px 0;
  }
  @media (max-width: 576px) {
    grid-gap: 10px;
    button {
      border-radius: 5px !important;
    }
  }
}
.buttongroupWrapColumns {
  @media (max-width: 576px) {
    grid-gap: 10px;
    border-radius: 5px;
  }
}
.categoryTitleMargin {
  margin-bottom: 36px;
}

.buttongroupWrapMargin {
  margin-bottom: 38px;
}

.dropDownTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.radioButton {
  background: #e7effe;
}

.mh162 {
  min-height: 162px;
}
.mh100 {
  min-height: 100px;
}
.mh50 {
  min-height: 50px;
}

.listHead {
  border-top: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  background: #e0e0e0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.listHeadDropdown {
  font-size: 14px;
  padding: 6px 18px 6px;
  font-weight: 600;
  @media (max-width: 550px) {
    font-size: 11px;
    padding: 6px 10px 6px;
    font-weight: 300;
  }
}

@media (max-width: 576px) {
  .searchConditionSection{
    .buttongroupWrap {
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 576px) {
        button.btn {
          font-size: 16px;
        }
      }
    }
    .searchButtonContainer {
      button.searchButton {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .searchConditionWrap{
      padding-bottom: 110px;
      .easingAndSort {
        position: relative;
        .searchButtonContainer {
          margin-bottom: 0;
          position: absolute;
          bottom: -50px;
          left: 0;
          width: 100%;
        }
      }
      .others {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        &.dispplaynne {
          display: none !important;
        }
      }
    }
  }
}

.listBody {
  padding: 5px;
  border: 1px solid var(--background_color);
  background: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-feature-settings: "palt";
  text-align: center;
  @media (max-width: 576px){
    font-size: 15px;
  }
  a {
    text-decoration: underline;
  }
  &.miniText {
    font-size: 12px;
    text-align: left;
    align-items: flex-start;
    justify-content: start;
  }
  &.approvalText {
    font-size: 12px;
  }
}

.addButton {
  background: var(--font_color);
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  // aspect-ratio: 1/1;
  width: 30px;
  height: 30px;
  line-height: 30px;
  &:hover {
    cursor: pointer;
  }
}

.netBtn {
  font-size: 14px;
  font-weight: 600;
  padding: 11px 29px 11px 13px;
  border-radius: 50px;
  line-height: 1;
  position: relative;
  text-decoration: none !important;
  &:after {
    content: "";
    position: absolute;
    padding: 9px;
    background: url("../../../public/img/netbtn_icon.svg") no-repeat;
    background-position: center;
    background-size: 1em;
    top: 50%;
    transform: translateY(-50%);
    right: 6%;
  }
  @media (max-width: 576px){
    font-size: 12px;
    padding: 11px 16px 11px 0;
    width: 100%;
  }
  span.sub {
    display: block;
    font-size: 10px;
    padding-top: 5px;
  }
}

.netBtnee {
  font-size: 14px;
  font-weight: 600;
  // padding: 11px 29px 11px 13px;
  border-radius: 50px;
  line-height: 1;
  position: relative;
}


//height
.h50 {
  height: 50px;
}
.h100 {
  height: 100px;
}
.h60 {
  height: 60px;
}
.h75 {
  height: 75px;
}
.h102 {
  height: 102px;
}
.h162 {
  height: 162px;
}

.blackFilterView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  overscroll-behavior: contain;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  &::after,
  &::before {
    content: "";
    width: 1px;
    height: calc(100vh + 1px);
    display: flex;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.myPlanResultPopup {
  .cartList {
    .item {
      display: flex;
      align-items: center;
      .thumbnailCon {
        width: 17%;
        display: block;
        span {
          font-size: 14px;
          display: block;
        }
      }
      .itemInfo {
        width: 30%;
        font-weight: 700;
        display: block;
      }
      .priceAndButton {
        width: 53%;
        display: flex;
        align-items: center;
        .price {
          font-weight: 700;;
          width: 30%;
          margin-top: 0;
          text-align: right;
        }
        .buttonWrap {
          text-align: center;
          width: 70%;
          button {
            font-size: 12px;
          }
        }
      }
    }
    @media (max-width: 576px) {
      .item {
        display: block;
        height: auto;
        position: relative;
        margin-bottom: 40px;;
        .thumbnailCon {
          display: flex;
          flex-flow: column;
          width: auto;
          span {
            font-size: 12px;
            margin-top: 10px;
            width: 50%;
          }
        }
        .itemInfo {
          width: auto;
        }
        .priceAndButton {
          display: block;
          .price {
            width: auto;
            text-align: center;
          }
          .buttonWrap {
            width: auto;
          }
        }
      }
    }
  }
  .total{
    .totalPrice {
      font-weight: 700;
      font-size: 20px;
    }
  }
}