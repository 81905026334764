// Button CSS

.scrollBackBtn {
  position: absolute;
  z-index: 15;
  top: -20px;
  right: 20px;
  img {
    cursor: pointer;
  }
}

.arrowButton {
  width: 56px;
  height: 56px;
  background: var(--main_button_color);
  color: #fff;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// Card Css

// ProgressBar Css
.simpleProgressBar {
  height: 32px;
  width: 100%;
  background: var(--sub_color_3);
  position: fixed;
  top: 0;
  z-index: 10;
}

.simpleProgressBar div {
  height: 32px;
  background: var(--accent_color);
  transition: width 0.3s ease-in-out;
  transition-delay: 0.6s;
}

// Typografy
.typographyBlock {
  display: block;
}
.typographyAlignCenter {
  text-align: center;
}
.typographyAlignRight {
  text-align: right;
}
.typographyPrimaryColor {
  color: var(--font_color);
}
.typographySecondaryColor {
  color: #5e5e5e;
}
.typographyAccentOrange {
  color: var(--accent_color);
}
.typographyAccentgree {
  color: #a3a3a3;
}
.typographyBlackColor {
  color: #000;
}
.typographyFontsizeXs {
  font-size: 10px;
}

.font5e{
  color: #5e5e5e !important;
}

.typographyFontsizeXXs {
  font-size: 8px;
}
.typographyFontFeaturePalt {
  font-feature-settings: "palt";
}

.typographyNumTextProgress {
  font-style: italic;
  font-size: 16px;
}
.typographyNumTextLarge {
  font-weight: 500;
  line-height: 100%;
  font-style: normal;
  font-size: 44px;
  letter-spacing: 2px;
  @media (max-width: 576px) {
    font-size: 32px;
  }
}
.typographyNumTextLabelLarge {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
}

.typographyToptitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
}
.typographyTopSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.typographyTextTitle {
  font-style: normal;
  font-size: 19px;
  line-height: 150%;
  font-weight: bold;
}
.typographyTextSupport {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
}
.typographyTextSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.5;
}

.typographyTextBody {
  font-style: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 15px;
  }
}
.right{
  text-align: right !important;
}

.typographyTextBodyBold {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
}

.typographyAnswerBtnLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
}
.typographyAnswerBtnDescription {
  color: #5e5e5e;
  font-weight: 300;
  font-size: 13px;
  padding-bottom: 8px;
}

.hasSmokedLink {
  margin-left: 16px;
  color: var(--main_color);
  cursor: pointer;
}
.typographyComparisonCategoryTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}
.typographyComparisonAccent {
  font-size: 16px;
  font-weight: 600;
}

// Grid CSS

.gridContainer {
  display: grid;
}
.grid1Column {
  display: grid;
  grid-template-columns: 1fr;
  gap: 18px;
}
.grid2Column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
}
.grid2ColumnFull {
  grid-column: 1/3;
}
.grid3Column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
}
.grid3ColumnFull {
  grid-column: 1/3;
}
.flexColumn {
  display: flex;
  flex-flow: column;
}

.multi-select-container {
  // display: flex; /* 要素を横に配置 */
  flex-wrap: wrap;
  label {
    // flex: 1; /* 各曜日を均等に幅分割 */
    margin-right: 12px; /* 曜日の間に適切な間隔を設定 */
    @media (max-width: 577px) {
      // flex: auto;
      margin-right: 8px;
    }
  }

}

.multi-select-containertel {
  flex-wrap: wrap; /* 複数のラベルを折り返す */
  label {
    // display: inline-block; /* ラベルを横に配置 */
    margin-right: 15px;
  }
}


.flexAlignCenter {
  display: flex;
  align-items: center;
}


.checkboxBtn {
  // padding: 15px 15px;
  padding: 0.5em;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
  font-size: 16px;
  // display: flex;
  gap: 0.5em;
  align-items: center;
  // flex-basis: 47%;
  color: #a3a3a3; // グレー
  


  input[type="checkbox"]:checked + span {
    color: black; // チェックが入った時、spanのテキストの色が変わる
  }
  @media (max-width: 577px) {
  input[type="checkbox"] {
    transform: scale(0.6);
  }
}
}
.inlinflex{
  display: inline-flex;
  
}
.flex{
  display: flex;
}



.contact-complete-form {
  label{
    margin-bottom: 20px !important;
  }
.checkboxBtnafter {
  padding: 20px 10px;
  border: 1px solid #5e5e5e;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  gap: 0.5em;
  align-items: center;
}
}
.backtopbtn{
  border: 0;
  background-color: #fff;
  color: #a3a3a3;
}
.backtopbtn:hover {
  background-color: #fff;
  color:#5e5e5e;
}
.backtopbtn:active{
  background: #fff !important;
  color: #5e5e5e !important;
}

.downloadDocumentSubmit {
  margin-top: 10px;
  padding: 20px 0;
  border: none;
  color: #fff;
  background: var(--main_button_color);
  font-size: 18px;
  border-radius: 8px;
}

.policyClosedBtn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  right: -30px;
  background: var(--main_button_color);
  border-radius: 50%;
  z-index: 20;
  cursor: pointer;
  span {
    width: 25px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    line-height: 1;
    &:first-of-type {
      transform: translate(-50%) rotate(45deg);
    }
    &:last-of-type {
      transform: translate(-50%) rotate(-45deg);
    }
  }

  @media (max-width: 576px) {
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    span {
      width: 15px;
      height: 2px;
    }
  }
}
