.card-align {
  margin-top: -14px;
  margin-right: 28px;
  text-align: right;
}

.complete-ctrl {
  text-align: right;
  margin-top: -48px;
  button {
    background: var(--main_color);
    font-size: 16px;
    height: 56px;
    width: 82px;
  }
}

.simpleProgressBar {
  height: 32px;
  width: 100%;
  background: var(--sub_color_3);
  position: fixed;
  top: 0;
  z-index: 10;
}
.simpleAsstssmentForm {
  width: 100%;
  max-width: 592px;
  margin: 40px auto 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 0 24px;
  input[type="text"] {
    border: 1px solid #afb7cc;
  }
  button {
    border: none;
  }
}
.calcContainer {
  margin-top: 40px;
  text-align: center;
}
.calcMan {
  margin-bottom: -32px;
}
.blueCard {
  padding: 32px;
  background: var(--sub_color_2);
  border-radius: 4px;
  width: 100%;
  text-align: left;
  color: var(--font_color);
  line-height: 1.5;
  h1 {
    font-size: 19px;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 17px;
    margin-bottom: 16px;
  }
  p {
    font-size: 15px;
    &:first-of-type {
      margin-bottom: 8px;
    }
  }
}
.textBtn {
  text-align: right;
  button {
    margin-top: 25px;
    width: 132px;
    height: 56px;
    background: var(--main_button_color);
    color: #fff;
    border-radius: 200px;
    text-align: center;
    border: none;
  }
}
.cardContainer {
  margin-top: 24px;
  position: relative;
}
.cardWrapper {
  padding: 40px 32px 32px;
  background: var(--sub_color_1);
  border-radius: 4px;
  @media screen and (max-width: 500px) {
    padding: 20px 16px 16px;
  }
  .titleImage {
    text-align: center;
    margin: 4px 0 21px;
  }
  fieldset {
    border: none;
    margin: 16px 0 0 0;
    padding: 0;
    label {
      input[aria-checked="false"] + div {
        border: 1px solid #afb7cc;
        color: #d0d0d0;
        img {
          opacity: 0.5;
        }
      }
      input[checked="true"] + div,
      input[aria-checked="true"] + div {
        border: 4px solid var(--main_color);

        color: #000;
        img {
          opacity: 1;
        }
      }
      div {
        padding: 16px;
        border-radius: 8px;
        text-align: center;
        span {
          font-weight: 600;
          display: block;
        }
      }
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
}
.question {
  fieldset {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    label {
      div {
        span {
          font-size: 21px;
        }
      }
    }
    &.checkSelectBox {
      div {
        border: 1px solid #afb7cc;
        color: #d0d0d0;
      }
      .checkedActive {
        div {
          border: 4px solid var(--main_color);
          color: #000;
        }
      }
    }
  }
}
.questionTxt {
  fieldset {
    div {
      position: relative;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &::before {
        content: attr(data-prefix);
        position: absolute;
        top: 50%;
        left: 24px;
        font-size: 15px;
        transform: translateY(-50%);
      }
      &:after {
        content: attr(data-suffix);
        position: absolute;
        top: 50%;
        right: 24px;
        font-size: 15px;
        transform: translateY(-50%);
      }
      input {
        padding: 11px 60px 11px 80px;
        width: 100%;
        min-width: 190px;
        height: 64px;
        border-radius: 8px;
        font-size: 28px;
        outline: none;
        text-align: right;
      }
    }
  }
}
.question1 {
  fieldset {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    label {
      &:first-of-type {
        grid-column: 1/3;
      }
      &:last-of-type {
        grid-column: 1/3;
      }
      div {
        span {
          font-size: 21px;
          &:nth-of-type(2) {
            font-size: 13px;
            color: #000;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.questionCheckbox {
  cursor: pointer;
  padding: 10px 24px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 263px;
    height: 64px;
    span {
      margin-left: 16px;
      font-size: 17px;
      font-weight: 600;
      color: var(--font_color);
    }
  }
}
.quesitonList {
  padding: 19px 24px;
  width: 100%;
  min-width: 263px;
  height: 64px;
  border: 1px solid #afb7cc;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 700;
  color: var(--font_color);
  outline: none;
}

.currentInsuranceCompanyWrapInput {
  max-height: 165px;
  width: 100%;
  border: 1px solid #afb7cc;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  margin-top: 8px;
  button {
    min-width: 100%;
    min-height: 53px;
    text-align: left;
    padding: 16px 24px;
    border: none;
    background: #fff;
  }
}
