@charset "utf-8";

// elements
.main {
  max-width: 1000px;
  width: 92%;
  margin: 80px auto 0;
  padding-bottom: 120px;
  @media (max-width: 576px){
    margin: 60px auto 0;
    width: 97%;
  }
  .twoColumns {
    display: flex;
    column-gap: 20px;
    position: relative;
    .left {
      .leftCard {
        width: 817px;
        flex-shrink: 0;
        @media (max-width: 1400px) {
          width: 717px;
        }
        @media (max-width: 1200px) {
          width: 100%;
        }
        .cardBody {
          padding: 40px;
          @media (max-width: 576px) {
            padding: 15px;
          }
          .top {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            margin-bottom: -50px;
            row-gap: 30px;
            @media (max-width: 576px) {
              grid-template-columns: repeat(1, 1fr);

              row-gap: 20px;
            }
            & > img {
              @media (max-width: 576px) {
                grid-row: 2/3;
              }
            }
            .names {
              padding-left: 15px;
              border-left: 5px solid var(--accent_color);
              h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 150%;
              }
              @media (max-width: 576px) {
                grid-row: 1/2;
              }
              p {
                font-size: 18px;
              }
            }
            .applyButtonCon {
              display: flex-end;
              text-align: right;
              @media (max-width: 576px) {
                grid-row: 4/5;
              }
              button {
                width: 191px;
                height: 74px;
                border-radius: 5px;
                @media (max-width: 576px) {
                  width: 100%;
                }
              }
            }
            .fontred{
              color: red;
            }
            .fontcloro5e{
              color: #5E5E5E;
              font-size: 12px;
            }
            .priceAndCondition {
              grid-column: 1/4;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              @media (max-width: 830px) {
                grid-row: 3/4;
                flex-flow: column;
              }
              .price {
                column-gap: 20px;
                .title {
                  color: var(--font_color);
                  font-size: 16px;
                  font-weight: 600;
                }
                .number {
                  color: var(--accent_color);
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 150%;
                }
              }
              .condition {
                padding-right: 150px;
                @media (max-width: 1400px) {
                  padding-right: 50px;
                }

                @media (max-width: 576px) {
                  padding-right: 0;
                }
              }
              .conditionmargi {
                padding-right: 150px;
                @media (max-width: 1400px) {
                  padding-right: 30px;
                }

                @media (max-width: 576px) {
                  padding-right: 0;
                }
              }
            }
          }
          .priceAndConditionunder {
            grid-column: 1/4;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: -25px;
            @media (max-width: 830px) {
              // grid-row: 3/4;
              flex-flow: column;
            }
            .price {
              column-gap: 20px;
            }
            .condition {
              padding-right: 150px;
              @media (max-width: 1400px) {
                padding-right: 50px;
              }

              @media (max-width: 576px) {
                padding-right: 0;
              }
            }
            .conditionmargi {
              padding-right: 150px;
              @media (max-width: 1400px) {
                padding-right: 30px;
              }

              @media (max-width: 576px) {
                padding-right: 0;
              }
            }
            .priceAndConditionunderList {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                display: inline;
                margin-right: 1.2em;
              }
            }
          }
          .points {
            .texts {
              margin-bottom: 10px;
              h3 {
                font-size: 17px;
                line-height: 1.5;
                font-weight: 600;
                font-style: normal;
              }
              p {
                margin-top: 10px;
              }
            }
            .buttonBox {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 17px;
              margin-top: 55px;
              @media (max-width: 576px) {
                margin-top: 50px;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 20px;
              }
              a,
              button {
                padding: 24px 0;
              }
            }
          }
        }
      }
      .backButtonCon {
        position: fixed;
        bottom: 20px;
        background-color: #fff;
        a {
          display: inline-block;
        }
      }
    }
    .rightCard {
      width: 340px;
      flex-shrink: 0;
      max-height: 702px;
      position: sticky;
      top: 150px;
      @media (max-width: 1200px) {
        display: none;
      }
      .cardBody {
        padding: 35px 37px;
        & > img {
          display: block;
          margin: 0 auto;
          margin-top: 20px;
        }
        h2 {
          font-size: 32px;
          line-height: 1.5;
          font-size: normal;
          font-weight: 600;
        }
        .insuranceFee {
          display: flex;
          justify-content: center;
          column-gap: 20px;
          margin: 30px 0;
          .title {
            color: var(--font_color);
            font-size: 16px;
            font-weight: 600;
          }
          .number {
            color: var(--accent_color);
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }
        }
        .buttonBox {
          row-gap: 20px;
          a {
            padding: 24px 0;
            display: block;
            margin-bottom: 20px;
          }
          button {
            padding: 24px 0;
            width: 100%;
            margin-bottom: 20px;
          }
        }
        .priceAndConditionunder {
          p {
            margin-bottom: 5px;
          }
          ul {
            font-size: 12px;
            color: #5e5e5e;
            margin-bottom: 1em;
            li {
              display: inline;
              margin-right: 1.2em;
            }
          }
        }
      }
    }
  }
}

// components
.headerLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 39px;
  font-feature-settings: "palt";
  img {
    border: 2px solid #fff;
  }
  @media (max-width: 992px) {
    gap: 20px;
  }
  @media (max-width: 576px) {
    flex-flow: column;
    align-items: baseline;
    gap: 16px;
  }
}
.headertitle {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.headerWrap {
  display: flex;
  justify-content: space-between;
  background: var(--font_color);
  padding: 15px 60px;
  @media (max-width: 576px) {
    padding: 16px 24px;
  }
}
.netBtn {
  font-size: 14px;
  font-weight: 600;
  padding: 11px 29px 11px 13px;
  line-height: 1;
  position: relative;
}
.buttonIcon {
  &:after {
    content: "";
    position: absolute;
    padding: 9px;
    background: url("../../../public/img/netbtn_icon.svg") no-repeat;
    background-position: center;
    background-size: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
}
.alineleft{
  margin-top: 10px;
}
.buttonmargin{
  margin: 10px;
}
.buttonRadius {
  border-radius: 50px;
}
.alignBaseline {
  display: flex;
  flex-flow: row;
  align-items: baseline;
}

.cordColumn {
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 10px;
}

.cordColumnTitle {
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--font_color);
  padding: 10px 0;
  border-top: 2px solid var(--font_color);
  border-bottom: 2px solid var(--font_color);
  span {
    line-height: 48px;
    padding-left: 10px;
    font-feature-settings: "palt";
    font-size: 24px;
  }
}

.table {
  display: grid !important;
  grid-template-columns: 40% 60%;
  border: none !important;
  dt {
    font-weight: 600;
    background: #eaecef;
    padding: 18px 0 18px 10px;
    border-top: 1px solid #000;
    &:last-of-type {
      border-bottom: 1px solid #000;
    }
  }
  dd {
    padding: 18px 0 18px 15px;
    border-top: 1px solid #000;
    font-weight: bolder;
    color: #777;
    &:last-of-type {
      border-bottom: 1px solid #000;
    }
  }
  @media (max-width: 576px) {
    dt {
      font-size: 15px;
      padding: 15px 10px;
    }
    dd {
      font-size: 15px;
      padding: 15px 10px;
    }
  }
}
.listMargin {
  margin-bottom: 30px;
}
.sectionMargin {
  margin-top: 90px;
}
.backButtonContadd {
	background: #02153e;
	bottom: 0;
	left: 0;
	position: fixed;
	width: 100%;
	text-align: center;
  @media (max-width: 576px){
    button {
      font-size: 14px;
      width: calc(50% - 15px);
      height: 45px;
      &.buttonmargin {
        margin: 10px 5px;
      }
    }
  }
}
.backButtonCon a {
  font-size: 10px;
}