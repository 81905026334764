@charset "utf-8";

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.card-list {
  justify-content: space-between;

  .connector {
    width: 13px;
    @media (max-width: 576px) {
      width: 8px;
    }
  }
}

.download-fp-section {
  width: 100%;
  max-width: 592px;
  margin: 0 auto;
  @media (max-width: 576px) {
    width: 92%;
  }
}
.popUpClose{
  display: none;
}
.popUpOpen {
  display: block;
  .closePopup{
    position: fixed;
    font-size: 12px;
    left: calc(50% + 255px);
    position: fixed;
    top: 5px;
    padding: 0;
    line-height: 4;
    width: 4em;
    border-radius: 50%;
    @media (max-width: 630px) {
      left: unset;
      right: 10px;
      top: 40px;
    }
  }
  .wrap {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    padding: 40px 0 0;
    background: rgba($color: #000000, $alpha: 0.3);
    z-index: 1000;
    @media (max-width: 576px) {
      padding-top: 80px;
    }
    .mainCont {
      background: #fff;
      margin: 0 auto;
      max-width: 97%;
      text-align: left;
      width: 600px;
      max-width: calc(100vw - 40px);
      border-radius: 10px;
      padding: 0;
      .scrollWrap {
        font-size: 14px;
        overflow: auto;
        padding: 12px;
        max-height: calc(100vh - 60px);
        @media (max-width: 576px) {
          max-height: calc(100vh - 220px);
          padding: 1.5em;
        }
      }
      &.mainContPlusButton {
        padding-bottom: 10px;
        @media (max-width: 576px) {
          padding-bottom: 1em;
        }
        .scrollWrap {
          max-height: calc(100vh - 120px);
          @media (max-width: 576px) {
            max-height: calc(100vh - 220px);
          }
        }
        .linkButton {
          padding-top: 0.5em;
          text-align: center;
          @media (max-width: 576px) {
            padding-left: 1em;
            padding-right: 1em;
            padding-top: 1em;
          }
        }
      }
      @media (max-width: 630px) {
        font-size: 12px;
      }
      .headArea {
        p {
          font-size: 14px;
          @media (max-width: 630px) {
            font-size: 12px;
          }
        }
        p.aR{
          text-align: right;
        }
      }
      h2{
        font-size: 18px;
        text-align: center;
        line-height: 3;
        font-weight: bold;
      }
      ol{
        padding-left: 1.5rem;
        li {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        ol.sub{
          list-style: none;
          padding-left: 1.5rem;
          li{
            list-style-type: none;
            counter-increment: cnt;
            text-align: left;
            text-indent: -1.5rem;
            &.subCount1 {
              counter-reset: item 1;
            }
          }
          li:before{
            content: "(" counter(cnt) ") ";
          }
        }
        ol.likeSub {
          list-style: none;
          padding-left: 1.5rem;
          li {
            list-style-type: none;
            text-align: left;
            text-indent: -1.5rem;
          }
        }
      }
      table {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        td{
          border:1px solid #aaa;
          width: 50%;
          padding: 3px;
        }
        &.half {
          width: 50%;
          td {
            width: auto;
          }
        }
        &.triple {
          .tripleCols {
            width: 33.33%;
          }
          td {
            width: inherit;
          }
        }
      }
    }
  }
}
