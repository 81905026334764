@charset "utf-8";

.downloadDocumentForm {
  display: flex;
  flex-flow: column;
  padding: 20px;
  font-size: 18px;
  border-radius: 8px;
  background: #fff;
  label {
    margin-bottom: 15px;
    input,
    select {
      border: 1px solid #afb7cc;
      font-size: 18px;
      padding: 20px 10px;
      border-radius: 12px;
      text-align: center;
      &::placeholder {
        text-align: center;
        color: #afb7cc;
      }
      outline: none;
    }
  }
  .contactMesssage {
    font-size: 13px;
    text-align: center;
    margin-top: 1em;
    color: #5e5e5e;
  }
}
.downloadDocumentFormtel {
  display: flex;
  padding: 20px;
  font-size: 18px;
  border-radius: 8px;
  background: #fff;
  label {
    margin-bottom: 15px;
    input,
    select {
      border: 1px solid #afb7cc;
      font-size: 18px;
      padding: 20px 10px;
      border-radius: 12px;
      text-align: center;
      width: 90%;
      margin-right: 25px;
      &::placeholder {
        text-align: center;
        color: #afb7cc;
      }
      outline: none;
    }
  }
}




.policyModal {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 100px;
  display: flex;
  justify-content: center;
  background: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  @media (max-width: 576px) {
    padding: 30px;
  }
  .policyModalWrap {
    position: relative;
  }
}
.policyModalWrap{
  @media (max-width: 576px) {
    width: 100%;
  }
}