.saveDiagnoseResultPopup {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 20px 0;
  position: fixed;
  top: 400px;
  width: 500px;
  z-index: 2;
  .textCon {
    text-align: center;
    padding-bottom: 20px;
  }
  @media (max-width: 576px) {
    top: 50%;
    width: calc(100vw - 30px);
    left: 15px;
    transform: translateY(-50%);
  }
}
