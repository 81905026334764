@charset "utf-8";

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.simpleAssessmentForm {
  width: 100%;
  max-width: 592px;
  margin: 40px auto 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 0 24px;
  position: relative;
  input[type="text"] {
    border: 1px solid #afb7cc;
  }
  button {
    border: none;
  }
}
.calcContainer {
  margin-top: 40px;
  text-align: center;
}
.calcMan {
  margin-bottom: -32px;
}
.blueCard {
  padding: 32px;
  background: var(--sub_color_2);
  border-radius: 4px;
  width: 100%;
  text-align: left;
  color: var(--font_color);
  line-height: 1.5;
  h1 {
    font-size: 19px;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 17px;
    margin-bottom: 16px;
  }
  p {
    font-size: 15px;
    &:first-of-type {
      margin-bottom: 8px;
    }
  }
}
.textBtn {
  text-align: right;
  button {
    margin-top: 25px;
    width: 132px;
    height: 56px;
    background: var(--main_button_color);
    color: #fff;
    border-radius: 200px;
    text-align: center;
    border: none;
  }
}
.cardContainer {
  margin-top: 24px;
  position: relative;
}

.currentCard {
  height: calc(100vh - 140px);
}

.cardWrapper {
  padding: 40px 32px 32px;
  background: #fff;
  border-radius: 4px;
  .titleImage {
    text-align: center;
    margin: 4px 0 21px;
  }
  fieldset {
    border: none;
    margin: 16px 0 0 0;
    padding: 0;
    label {
      input[aria-checked="false"] + div {
        border: 1px solid #afb7cc;
        color: #d0d0d0;
        img {
          opacity: 0.5;
        }
      }
      input[aria-checked="true"] + div {
        border: 4px solid var(--main_color);

        color: #000;
        img {
          opacity: 1;
        }
      }
      div {
        padding: 16px;
        border-radius: 8px;
        text-align: center;
        span {
          font-weight: 600;
          display: block;
        }
      }
    }
  }
  input[type="radio"] {
    display: none;
  }
}
.question {
  fieldset {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    label {
      div {
        span {
          font-size: 21px;
        }
      }
    }
  }
}
.questionTxt {
  fieldset {
    div {
      position: relative;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &::before {
        content: attr(data-prefix);
        position: absolute;
        top: 50%;
        left: 24px;
        font-size: 15px;
        transform: translateY(-50%);
      }
      &:after {
        content: attr(data-suffix);
        position: absolute;
        top: 50%;
        right: 24px;
        font-size: 15px;
        transform: translateY(-50%);
      }
      input {
        padding: 11px 60px 11px 80px;
        width: 100%;
        min-width: 190px;
        height: 64px;
        border-radius: 8px;
        font-size: 28px;
        outline: none;
        text-align: right;
      }
    }
  }
}

.question1 {
  fieldset {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
    label {
      &:first-of-type {
        grid-column: 1/3;
      }
      &:last-of-type {
        grid-column: 1/3;
      }
      div {
        span {
          font-size: 21px;
          &:nth-of-type(2) {
            font-size: 13px;
            color: #000;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.question5 {
  fieldset {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(3, 1fr);
    label {
      &:first-of-type {
        grid-column: 1/4;
      }
      &:last-of-type {
        grid-column: 1/4;
      }
      div {
        span {
          font-size: 21px;
        }
      }
    }
  }
}

.error {
  input {
    border-color: #f30 !important;
    color: #f30 !important;
  }

  div {
    &:before {
      color: #f30 !important;
    }

    &:after {
      content: attr(data-suffix);
      color: #f30 !important;
    }
    margin-bottom: 5px !important;
  }

  .validation {
    color: #f30;
    font-size: 11px;
    font-weight: 300;
    margin-left: 25px;
  }
  &.multi-select-container {
    &.multi-select{
      .validation {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  &.multi-select-containertel {
    &.multi-select {
      .validation {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
