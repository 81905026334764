html {
  --main_color: #06f;
  --sub_color_1: #fff;
  --sub_color_2: #e0edef;
  --sub_color_3: #cddcdd;
  --accent_color: #f60;
  --font_color: #00153e;
  --background_color: #f1f6f6;
  --main_button_color: #003584;
  --sub_button_color_1: #f60;
  --sub_button_color_2: #fff;
  --sub_button_color_3: #fff;
}
