#result-simple {
  .cardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .cardContainer {
    padding: 16px 10px;
    margin: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    .recommendedInsuranceItems_table {
      .recommendedInsuranceItems_child {
        > div {
          width: 50%;
        }
        .insuranceType {
          font-size: 13px;
        }
        .insuranceTypeMemo {
          .typographyPrimaryColor {
            line-height: 1.3;
          }
          @media (min-width: 576px) {
            &.isClose {
              .insuranceType {
                padding-top: 0.8em;
              }
            }
          }
        }
      }
    }
    @media (max-width: 576px) {
      padding-bottom: 30px;
      .recommendedInsuranceItems_table {
        li {
          border-bottom: 1px solid #ddd;
          margin-bottom: 0;
          padding-bottom: 10px;
        }
        .recommendedInsuranceItems_child {
          > div:first-child {
            text-align: center;
            margin-bottom: 8px;
          }
          > div {
            width: auto;
          }
        }
      }
    }
  }

  .moreEstimateButton {
    display: none;
  }

    .canSaveContainer {
    height: 25vh;
    @media (max-width: 576px) {
      canvas{
        &.chart-fs {
          margin: 0;
        }
        max-width: 100%;
        max-height: 100%;
      }
      width: 100%;
    }
  }
  .compensationChartContainer {
    height: 25vh;
    @media (max-width: 576px) {
      canvas{
        &.chart-fs {
          margin: 0;
        }
      }
      width: 100%;
    }
    canvas {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .simpleAssessmentResult_layout {
    max-width: 592px;
    margin: 0 auto;
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .termPopup {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2000;
      background: rgba(255, 255, 255, 0.9);
      .innerRermPopup{
        background: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,.2);
        height: 100%;
        margin: 0 auto;
        max-width: 540px;
        padding: 30px 10px 10px;
        position: relative;
        .innerScroll {
          border: 1px solid #aaa;
          max-height: calc(100vh - 40px);
          overflow: auto;
          padding: 30px 10px 10px;
          .aR {
            text-align: right;
          }
        }
        button{
          position: absolute;
          right: 10px;
          top: 5px;
          border: none;
          background: #103869;
          display: block;
          color: white;
          font-size: 12px;
          padding: 0;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
        }
        p {
          font-size: 0.7rem;
        }
        h2 {
          font-size: 1.5rem;
          line-height: 40px;
          margin-bottom: 10px;
          text-align: center;
          margin-top: 10px;
          @media (max-width: 576px) {
            font-size: 1rem;
          }
        }
        ol {
          font-size: .7rem;
          line-height: 1.7;
          margin-bottom: 5px;
          padding-left: 20px;
          li {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
        table {
          td {
            border: 1px solid #aaa;
            padding: 5px;
            width: 50%;
            vertical-align: top;
          }
        }
      }
    }
    .isClose{
      top: -110vh;
      background: rgba(255, 255, 255, 0);
      transition: 0.3s;
    }
  }
  .simpleAssessmentResult_title {
    height: 98px;
  }

  .assessmentTitle_header {
    text-align: center;
    padding: 48px 0 46px;
  }

  .textsizeadd {
    font-size: 28px; /* デフォルト値 */
  }
  
  @media only screen and (min-width: 600px) {
    .textsizeadd {
      font-size: 37px; /* PC用の値 */
    }
  }
  .fixedBottomButton {
    position: fixed;
    bottom: 0;
    margin-left: -24px;
    height: 64px;
    width: 100vw;
    max-width: 592px;
    a {
      display: block;
      width: 100%;
      height: 64px;
      background-color: var(--main_button_color);
      color: #fff;
      text-align: center;
      line-height: 64px;
      cursor: pointer;
    }
  }
  .fixedBottomButtonContainer {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    background: #02153E;
    padding: 10px 0;
    text-align: center;
    section {
      display: inline-block;
      vertical-align: middle;
      width: 470px;
      button {
        padding: 0.8rem 0 !important;
      }
      &.hidden {
        display: none;
      }
      &.fixedBottomButtonnew {
        padding: 0px 4px 0px 4px;
        button {
          font-weight: 600;
          font-size: 14px;
        }
      }
      &.fixedBottomButton2 {
        padding: 0px 4px 0px 4px;
        a {
          display: block;
        }
        button{
          background-color: #fff;
          font-weight: 600;
          font-size: 14px;
          width: 100%;
        }
        .btn:hover {
          color: #000 !important; /* ホバー時の文字色 */
        }
      }
    }
    @media (max-width: 576px) {
      section {
        width: calc(100% - 10px);
      }
    }
    &.bothButton {
      section {
        width: 275px;
        @media (max-width: 576px) {
          width: calc(50vw - 5px);
        }
      }
    }
  
  }
  
  .compensationAmount_estimate {
    text-align: right;
  }
  .compensationAmount_section {
    margin-bottom: 40px;
  }
  .compensationAmount_supportText {
    margin-top: 34px;
  }
  .compensationAmount_yen {
    font-style: normal;
    font-size: 24px;
    line-height: 150%;
    color: #000;
  }

  .canSave_estimate {
    margin-top: 40px;
    text-align: right;
  }
  .canSave_price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    margin-right: 23px;
    @media (max-width: 576px) {
      margin-right: 0;
    }
  }
  .canSave_annual {
    font-size: 20px;
    line-height: 27px;
    color: #000;
    @media (max-width: 576px) {
      font-size: 17px;
      line-height: 22px;
    }
  }
  .canSave_yen {
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    color: #000;
    @media (max-width: 576px) {
      font-size: 17px;
      line-height: 22px;
    }
  }
  .recommendedInsuranceItems_title {
    font: 21px/31.5px Hiragino Kaku Gothic Pro;
    color: #000;
    font-weight: bold;
  }

  .recommendedInsuranceItems_subTitle {
    font: 300 13px Hiragino Kaku Gothic Pro;
    color: #222222;
  }
  .recommendedInsuranceItems_subsubTitle {
    padding-top: 5px;
    font: 100 12px Hiragino Kaku Gothic Pro;
    color: #5e5e5e;
  }
  .recommendedInsuranceItems_table {
    font-size: 12px;
    .col {
      padding: 0;
    }
  }
  .recommendedInsuranceItems_child {
    display: flex;
    column-gap: 15px;
    button {
      color: var(--main_color);
      background-color: var(--sub_color_1);
      border-color: var(--main_color);
      &:hover {
        color: var(--sub_color_1);
        background-color: var(--main_color);
      }
    }
    .insuranceName {
      margin-top: 4.75;
      text-align: center;
    }

    @media (max-width: 576px) {
      flex-flow: column;
    }
    .memoListBox{
      span {
        margin-right: 1em;
      }
    }
  }
  .recommendedInsuranceItems_number {
    font-size: 24px;
    font-weight: 500;
    margin-right: 4px;
  }

  .recommendedInsuranceItems_border {
    padding-top: 16px;
    border-top: 2px solid var(--font_color);
  }

  .recommendedInsuranceItems_totalNumber {
    font-size: 32px;
    font-weight: 600px;
  }

  .recommendedInsuranceItems_totalTitle {
    font-size: 18px;
  }

  .recommendedInsuranceItems_guardText {
    margin-top: 24px;
  }

  .saveDiagnosisResult_Offcanvas {
    max-width: 544px;
    margin: 0 auto;
    width: 100%;
    border-radius: 12px 12px 0% 0% / 12px 12px 0% 0%;
    --bs-offcanvas-height: auto;
    padding: 0 24px 32px;
    height: auto;
  }

  .content-float {
    justify-content: end;
    @media (max-width: 576px) {
      justify-content: start;
    }
  }

  .grid-repeat {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .total-insurance-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 115px;
    padding-right: 115px;
    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .btn-save {
    --bs-btn-bg: var(--sub_button_color_1);
    --bs-btn-color: #fff;
    --bs-btn-hover-bg: var(--sub_button_color_1);
    --bs-btn-hover-border-color: var(--sub_button_color_1);
    --bs-btn-hover-color: #fff;
  }

  margin-bottom: 40px;

  .approvalNumbers {
    font-size: 14px;
    margin-top: 0.5em;
    color: #5e5e5e;
    p {
      display: inline-block;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      li {
        display: inline-block;
        margin-right: 1em;
      }
    }
  }
}

.chart-fs {
  @media (max-width: 576px) {
    margin-left: -32px;
    margin-right: -32px;
  }
}
.mysubtitle{
  font-size: 11px;
  color: #5E5E5E;
}
