.resultBox {
  position: fixed;
  z-index: 2;
  top: 100px;
  right: 30px;
  width: 600px;
  height: 85vh;
  padding: 50px;
  background: #fff;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior: contain;
  @media (max-width: 576px) {
    position: fixed;
    height: calc(100vh - 40px);
    margin: 0;
    overflow: auto;
    padding: 30px;
    right: 5%;
    top: 20px;
    width: 90vw;
    z-index: 100;
  }
  .backButton {
    position: fixed;
    top: 100px;
    right: 30px;
    margin-top: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border: 2px solid #777;
    border-radius: 50%;
    text-indent: 12px;
    line-height: 32px;
    color: #777;
    font-weight: 600;
    font-size: 20px;
    aspect-ratio: 1/1;
    @media (max-width: 576px) {
      right: 4.5%;
      top: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .title {
    margin-bottom: 32px;
    h4 {
      font-weight: bold;
    }
  }
  .mysubtitle{
    font-size: 11px;
    color: #5E5E5E;
  }
  .cartList {
    /* overflow: auto; */
    margin-bottom: 5px;
    /* height: 290px; */
    .item {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 80px 152px 1fr;
      column-gap: 14px;
      @media (max-width: 992px) {
        grid-template-columns: 80px 1fr;
      }
      .priceAndButton {
        display: flex;
        gap: 9px;
        @media (max-width: 992px) {
          grid-column: 2/3;
        }
        .price {
          margin-top: 15px;
        }
      }
      @media (max-width: 576px) {
        display: block;
        position: relative;
        height: auto;
        .thumbnailCon {
          display: flex;
          flex-flow: column;
          span {
            margin-top: 10px;
            width: 50%;
            font-size: 12px;
          }
        }
        .itemInfo {
          span {
            margin: 5px auto;
            font-size: 14px;;
          }
        }
        .priceAndButton {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-flow: column;
          text-align: center;
          .price {
            margin: 0;
          }
        }
      }
    }
  }
  .total {
    border-top: 1px solid #000;
    padding-top: 10px;
    @media (max-width: 576px) {
      margin: 16px 0 17px;
      padding-top: 18px;
    }
    .totalPrice {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      div:first-child {
        margin-right: 140px;
        @media (max-width: 576px) {
          margin: 0;
        }
      }
      @media (max-width: 576px) {
        column-gap: 0;
        justify-content: space-between;
      }
    }
  }
  .buttonCon {
    margin-top: 50px;
    button {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      width: 50%;
      height: 60px;
    }
    .saveButton {
      width: 100%;
    }
    @media (max-width: 576px) {
      .contactButton {
        width: 100%;
      }
    }
  }
  .note {
    margin-top: 60px;
    font-size: 10px;
    @media (max-width: 576px) {
      margin-top: 34px;
    }
  }
}
