@charset "utf-8";

.image-card {
  width: 140px;
  height: 184px;
  padding: 17px 10px 22px 10px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    height: 42px;
  }

  label {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #002769;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    margin: 0 12px;
  }

  * {
    margin-bottom: 8px;
  }

  .badge {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 26px;
    height: 26px;
    border: solid 1px #002769;
    background-color: #fff;
    color: #002769;
    border-radius: 13px;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 4px 0;
  }

  @media (max-width: 576px) {
    width: 91px;
    height: 140px;
    padding: 17px 5px;

    img {
      height: 28px;
    }

    label {
      font-size: 10px;
    }

    span {
      font-size: 8px;
      margin: 0 8px;
      line-height: 1.3;
    }

    .badge {
      width: 18px;
      height: 18px;
      top: -5px;
      left: -5px;
      font-size: 10px;
    }
  }
}

.error {
  .checkboxBtn {
    border-color: #f30 !important;
    color: #f30 !important;
  }
}

.multi-select {
  .validation {
    margin-top: -8px;
  }
  margin-bottom: 8px;
}

.loading-frame {
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--background_color);
  display: flex;

  .content {
    min-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    .back {
      z-index: -100;
      width: 100%;
      top: 10vh;
      margin: 0;
      position: absolute;
    }

    .content-notify {
      margin: auto;
      width: 360px;
      background: var(--sub_color_1);
      display: flex;
      position: relative;
      flex-direction: column;

      .content-icon {
        margin: -140px auto 0 auto;
      }

      .content-waiting {
        margin: 40px auto;
      }

      label {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        color: var(--font_color);
        margin: 0 80px 40px;
      }
    }

    @media (max-width: 576px) {
      min-width: 320px;
      .content-notify {
        width: 300px;

        label {
          font-size: 16px;
          margin: 0 60px 40px;
        }
      }
    }
  }
}
