@charset "utf-8";

* {
  box-sizing: border-box;
}
blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: Hiragino Kaku Gothic Pro, Helvetica Neue, Arial,
    Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif;
  scroll-behavior: smooth;
  background: var(--background_color);
}
a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.btn-orange {
  --bs-btn-bg: var(--accent_color);
  --bs-btn-color: #fff;
  --bs-btn-hover-bg: var(--accent_color);
  --bs-btn-hover-border-color: var(--accent_color);
  --bs-btn-hover-color: #fff;
}
.btn-orangenew {
  --bs-btn-bg: #fff;
  --bs-btn-color:  var(--accent_color);
  --bs-btn-hover-color:#fff;
  --bs-btn-hover-bg: var(--accent_color);
  --bs-btn-border-color: var(--accent_color);
  --bs-btn-hover-border-color: var(--accent_color);
  
}
.center{
  margin-top: 10px;
  text-align: center;
}


.btn-blue {
  --bs-btn-bg: var(--main_button_color);
  --bs-btn-color: #fff;
  --bs-btn-hover-bg: #0055a4;
  --bs-btn-hover-border-color: var(--main_button_color);
  --bs-btn-hover-color: #fff;
}

.btn-naivy {
  --bs-btn-bg: var(--font_color);
  --bs-btn-hover-bg: var(--font_color);
  --bs-btn-hover-border-color: #fff;
  --bs-btn-hover-color: #fff;
  color: #fff;
}
.btn-naivyButtonGroup {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--font_color);
  --bs-btn-hover-border-color: var(--font_color);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--font_color);
  --bs-btn-active-border-color: var(--font_color);
}
.btn-dropdown {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #ced4da;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #ced4da;
  &:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0.5em;
    border-top: 0.6em solid;
    border-top-color: var(--accent_color);
  }
}
.btn-comparisonHeader {
  --bs-btn-color: var(--font_color);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: var(--font_color);
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #ced4da;
  --bs-btn-active-color: var(--font_color);
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #ced4da;
}

.mb-4 {
  margin-bottom: 40px;
}

.mr-18 {
  margin-right: 18px;
}

.mr-23 {
  margin-right: 23px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 15px;
}

.policy-modal {
  padding: 40px;
  width: 100%;
  max-width: 592px;
  background: #fff;
  border-radius: 8px;
  line-height: 1.7;
  position: relative;
  z-index: 10;
  color: #5e5e5e;
  p {
    margin-bottom: 1em;
    font-weight: 700;
  }
  ul {
    margin-bottom: 1em;
  }
  height: calc(100vh - 200px);

  .policy-title {
    padding-bottom: 40px;
  }

  .policy-content {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 350px);
    white-space: normal;
    word-break: break-all;
    ul {
      white-space: inherit;
      word-break: inherit;
      li {
        white-space: inherit;
        word-break: inherit;
      }
    }
    @media screen and (max-width: 500px) {
      height: calc(100vh - 170px);
    }
    margin-bottom: 60px;
    font-size: 12px;
    p {
      color: black;
    }
  }

  .policy-content::-webkit-scrollbar {
    width: 15px;
  }

  .policy-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  .policy-content::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 9px;
    border: 5px solid transparent;
    background-clip: content-box;
    height: 30px;
  }

  .policy-content::-webkit-scrollbar-thumb:hover {
    background: #aaa;
    border-radius: 9px;
    border: 5px solid transparent;
    background-clip: content-box;
    height: 30px;
  }

  @media (max-width: 576px) {
    height: calc(100vh - 60px);
    padding: 30px;

    .policy-title {
      padding-bottom: 20px;
    }
  }
}
.dispplaynne{
  display: none !important;
}
.subitemInfo{
  color: #5e5e5e;
  font-size: 11px;
  @media (max-width: 576px) {
    font-size: 10px;
  }
}