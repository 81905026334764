@charset "utf-8";

.contact-complete-section {
  width: 100%;
  max-width: 592px;
  margin: 20px auto;
  @media (max-width: 576px) {
    width: 92%;
  }
}

.contact-complete-form {
  display: flex;
  flex-flow: column;
  padding: 40px;
  font-size: 18px;
  border-radius: 8px;
  background: #fff;
  margin: 37px 32px;
  @media (max-width: 576px) {
    font-size: 10px;
    margin: 0;
  }

  span {
    color: var(--font_color);
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    @media (max-width: 576px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  label {
    color: #5e5e5e;
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
    @media (max-width: 576px) {
      font-size: 13px;
      margin: 0;
    }
  }
}

.contactCompleteSubmit {
  margin-top: 10px;
  padding: 20px 0;
  border: none;
  color: #fff;
  background: var(--main_button_color);
  font-size: 18px;
  border-radius: 12px;
}
