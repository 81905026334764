@charset "utf-8";

.topStartView {
  width: 100%;
  max-width: 592px;
  margin: 0px auto;
  padding: 40px 20px;
  .topBox {
    color: var(--font_color);
    margin-bottom: -10px;
    position: relative;
    .wrap {
      display: flex;
      flex-flow: column;
      .subTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }
      .topTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        @media (max-width: 576px) {
          font-size: 24px;
        }
      }
      img {
        width: 102px;
        height: 39px;
      }
    }
    .baloon {
      position: absolute;
      top: 0px;
      right: 0px;
      img {
        width: auto;
        height: 217.5px;
      }
      @media (max-width: 576px) {
        top: -30px;
        right: -10px;
        img {
          height: 175px;
        }
      }
    }
  }
  .personBox {
    text-align: center;
    img {
      width: 341px;
      height: 456px;
      max-width: 100%;
    }
    @media (max-width: 576px) {
      padding-top: 0;
      img {
        width: 70%;
        height: auto;
      }
    }
  }
  .introBox {
    -moz-box-align: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-top: -220px;
    @media (max-width: 576px) {
      margin-top: -300px;
    }
    img {
      width: 178.5px;
      height: 240px;
      margin-bottom: -16px;
      z-index: 10;
      @media (max-width: 576px) {
        width: 120px;
        height: auto;
      }
    }
    .message {
      padding: 16px 32px;
      background: var(--sub_color_1);
      width: 100%;
      border-radius: 8px;
      span {
        color: #0d6efd;
        cursor: pointer;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 1.5;
        color: var(--font_color);
        @media (max-width: 576px) {
          font-size: 14px;
          br {
            display: none;
          }
        }
      }
      &.messageTop {
        margin-bottom: 16px;
      }
    }
  }
  .startBox {
    text-align: right;
    margin-top: 20px;
    button {
      width: 132px;
      height: 56px;
      background: var(--main_button_color);
      color: rgb(255, 255, 255);
      border-radius: 200px;
      text-align: center;
      border: medium;
      padding: 1em;
      display: inline-block;
      font-size: inherit;
      line-height: inherit;
      cursor: pointer;
      color: #fff;
    }
  }
}
